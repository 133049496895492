import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import {getData} from "../../services/httpService";
import privateRoute from "../../routes/PrivateRoutes";
import webex from '../../assets/Images/webex.png';
import insights from '../../assets/Images/insight.png';
import gitlab from '../../assets/Images/gitlab.png';
import ChatHistory from "../../components/chatHistory/ChatHistory";
function IndexPage() {
    const [question, setQuestion] = useState('');
    const [questionArray, setQuestionArray] = useState([]);
    const [search, setSearch] = useState(false);
    const [chat, setChat] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('all');
    const [chatHistory, setChatHistory] = useState([]);
    const [webexChat, setWebexChat] = useState([]);
    const [allChat, setAllChat] = useState([]);
    const [insightChat, setInsightChat] = useState([]);
    const [gitLabChat, setGitLabChat] = useState([]);
    const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
    useEffect(() => {
        // Retrieve chat history from local storage on initial load
        getChatHistoryFromLocalStorage();
    }, []);
    const getChatHistoryFromLocalStorage = () => {
        setChatHistory([]);
        const webexChat = getChatFromSessionStorage('webex');
        const allChat = getChatFromSessionStorage('all');
        const insightChat = getChatFromSessionStorage('insights');
        const gitlabChat = getChatFromSessionStorage('gitlab');
        setWebexChat(webexChat);
        setAllChat(allChat);
        setInsightChat(insightChat);
        setGitLabChat(gitlabChat);

        // Insert first two values from each array to chatHistory
        setChatHistory(prevChatHistory => [
            ...prevChatHistory,
            ...webexChat.slice(0, 2).map(entry => ({ ...entry, source: 'webex' })),
            ...allChat.slice(0, 2).map(entry => ({ ...entry, source: 'all' })),
            ...insightChat.slice(0, 2).map(entry => ({ ...entry, source: 'insights' })),
            ...gitlabChat.slice(0, 2).map(entry => ({ ...entry, source: 'gitlab' }))
        ]);
    }
    useEffect(() => {
        console.log("CHAT HISTORY", chatHistory);
    },[chatHistory])
    useEffect(() => {
        if(search) {
            setQuestionArray(prevNames => [...prevNames, question]);
            setLoading(true);
            let searchQuestion = question;
            setQuestion('');
            if(selected === 'all' || selected === 'webex') {
                try{
                    getData(searchQuestion,'webex').then((response) => {
                        console.log(response);
                        if(response.data.text !== '') {
                            setChat(prevChat => [...prevChat, { type: selected, content: response.data.text, source: 'webex' }]);
                             }
                        else {
                            setChat(prevChat => [...prevChat, { type: 'webex', content: 'Found Nothing', source: 'webex' }]);
                             }

                        setSearch(false);
                        setLoading(false);
                    })
                } catch (e) {
                    console.log(e)
                    setLoading(false);
                }
            }
            if(selected === 'all' || selected === 'insights') {
                try{
                    getData(searchQuestion,'insights').then((response) => {
                        console.log(response);
                        setChat(prevChat => [...prevChat, { type: selected, content: response.data.text, source: 'insights' }]);
                        setSearch(false);
                        setLoading(false);
                    })
                } catch (e) {
                    console.log(e)
                    setLoading(false);
                }
            }
            if(selected === 'all' || selected === 'gitlab') {
                try{
                    getData(searchQuestion,'gitlab').then((response) => {
                        console.log(response);
                        setChat(prevChat => [...prevChat, { type: selected, content: response.data.text, source: 'gitlab' }]);
                       setSearch(false);
                        setLoading(false);
                    })
                } catch (e) {
                    console.log(e)
                    setLoading(false);
                }
            }
        }
    },[search])
    useEffect(() => {
        if(chat.length > 0) {
            saveChatToSessionStorage(selected,chat);
        }
    },[chat])
    const searchQuestion = () => {
        if (question.trim() === '') return; // Don't search if the question is empty
        setChat(prevChat => [...prevChat, { type: 'question', content: question }]);
        setSearch(true);
    }
    const toggleChatHistory = () => {
        if(!isChatHistoryOpen) {
            getChatHistoryFromLocalStorage();
        }
        setIsChatHistoryOpen(!isChatHistoryOpen);
    };

    useEffect(() => {
        const data = getChatFromSessionStorage(selected);
        setChat(data);
        },[selected])
    useEffect(() => {
        console.log(chat)
    },[chat])
    const setChatHistoryInitially = (type,content,source) => {
        if (selected === 'webex') {
            if (webexChat.length < 2) {
                console.log(allChat.length,"WEBEX CHAT LENTH")
                console.log(chatHistory,"CHAT HISTORY LENGTH")
                setWebexChat(prevChat => [...prevChat, {type: type, content: content, source: source}]);
                setChatHistory(prevState => [...prevState, {type: type, content: content, source: 'webex'}])
            }
        }
        if (selected === 'insights') {
            if (insightChat.length < 2) {
                console.log(allChat.length,"INSIGHT CHAT LENTH")
                console.log(chatHistory,"CHAT HISTORY LENGTH")
                setInsightChat(prevChat => [...prevChat, {type: type, content: content, source: source}]);
                setChatHistory(prevState => [...prevState, {type: type, content: content, source: 'insights'}])
            }
        }
        if (selected === 'gitlab') {
            if (gitLabChat.length < 2) {
                console.log(allChat.length,"GITLAB CHAT LENTH")
                console.log(chatHistory,"CHAT HISTORY LENGTH")
                setGitLabChat(prevChat => [...prevChat, {type: type, content: content, source: source}]);
                setChatHistory(prevState => [...prevState, {type: type, content: content, source: 'gitlab'}])
            }
        }
        if (selected === 'all') {
            if (allChat.length < 2) {
                console.log(allChat.length,"ALL CHAT LENTH")
                console.log(chatHistory,"CHAT HISTORY LENGTH")
                setAllChat(prevChat => [...prevChat, {type: type, content: content, source: source}]);
                setChatHistory(prevState => [...prevState, {type: type, content: content, source: 'all'}])
            }
        }
    }
    const saveChatToSessionStorage = (category, messages) => {
        localStorage.setItem(`chat_${category}`, JSON.stringify(messages));
    };

    const getChatFromSessionStorage = (category) => {
        const storedChat = localStorage.getItem(`chat_${category}`);
        return storedChat ? JSON.parse(storedChat) : [];
    };

    return (
        <>
            <div className="flex h-screen antialiased text-gray-800">
                <div className="flex flex-row h-full w-full overflow-x-hidden">
                    <div className="flex flex-col w-64 bg-white flex-shrink-0">
                        <Sidebar selected={selected} setSelected={setSelected} />
                    </div>
                    <div className="flex flex-col flex-auto h-full">
                        <div
                            className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full"
                        >
                             <div className="flex items-center justify-between bg-gray-200 p-4">
                                <h1 className="text-lg font-semibold">Chat App</h1>
                                <div className="flex space-x-4">
                                    {/*<button*/}
                                    {/*    className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 focus:outline-none"*/}
                                    {/*>*/}
                                    {/*    <svg*/}
                                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*        className="h-6 w-6"*/}
                                    {/*        viewBox="0 0 24 24"*/}
                                    {/*        strokeWidth="2"*/}
                                    {/*        stroke="currentColor"*/}
                                    {/*        fill="none"*/}
                                    {/*        strokeLinecap="round"*/}
                                    {/*        strokeLinejoin="round"*/}
                                    {/*    >*/}
                                    {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>*/}
                                    {/*        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>*/}
                                    {/*        <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>*/}
                                    {/*        <path*/}
                                    {/*            d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"*/}
                                    {/*        ></path>*/}
                                    {/*    </svg>*/}
                                    {/*</button>*/}
                                    {/*<button*/}
                                    {/*    className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 focus:outline-none"*/}
                                    {/*>*/}
                                    {/*    <svg*/}
                                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*        className="h-6 w-6"*/}
                                    {/*        viewBox="0 0 24 24"*/}
                                    {/*        strokeWidth="2"*/}
                                    {/*        stroke="currentColor"*/}
                                    {/*        fill="none"*/}
                                    {/*        strokeLinecap="round"*/}
                                    {/*        strokeLinejoin="round"*/}
                                    {/*    >*/}
                                    {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>*/}
                                    {/*        <path*/}
                                    {/*            d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"*/}
                                    {/*        ></path>*/}
                                    {/*        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>*/}
                                    {/*    </svg>*/}
                                    {/*</button>*/}
                                    <button
                                        className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 focus:outline-none"
                                        onClick={toggleChatHistory}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.956 9.956 0 0 1-4.708-1.175L2 22l1.176-5.29A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2Zm1 5h-2v7h6v-2h-4V7Z"/></svg>
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col h-full mb-4 p-4 overflow-y-auto bg-gray-100">
                                <div className="flex flex-col-reverse h-full overflow-y-auto">
                                    {chat.slice().reverse().map((message, index) => (
                                        <div
                                            key={index}
                                            className={`${
                                                message.type === 'question' ? 'justify-end' : 'justify-start'
                                            } flex flex-row`}
                                        >
                                            {
                                                (message.type !== 'question') &&
                                                <div
                                                    className="flex items-center justify-center mx-2 my-1 h-10 w-10 rounded-full bg-transparent flex-shrink-0"
                                                >
                                                    {
                                                        (message.source === 'webex') &&
                                                        <img
                                                            src={webex}
                                                            alt="Webex Avatar"
                                                            className="w-full h-full rounded-full object-contain"
                                                        />
                                                    }
                                                    {message.source === 'insights' && (
                                                        <img
                                                            src={insights}
                                                            alt="Teams Avatar"
                                                            className="w-full h-full rounded-full object-cover"
                                                        />
                                                    )}
                                                    {message.source === 'gitlab' && (
                                                        <img
                                                            src={gitlab}
                                                            alt="SharePoint Avatar"
                                                            className="w-full h-full rounded-full object-cover"
                                                        />
                                                    )}
                                                </div>
                                            }
                                            <div
                                                className={`relative text-sm py-2 my-2 px-4 shadow rounded-xl ${
                                                    message.type === 'question' ? 'bg-indigo-100' : 'bg-white'
                                                }`}
                                            >
                                                <div>{message.content}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4"
                            >
                                <div className="flex-grow ml-4">
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                                            onInput={(e) => setQuestion(e.target.value)}
                                            value={question}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    searchQuestion();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <button
                                        className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                                        onClick={searchQuestion}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <div className="loader">Loading...</div>
                                        ) : (
                                            <>
                                                <span>Send</span>
                                                <span className="ml-2">
                          <svg
                              className="w-4 h-4 transform rotate-45 -mt-px"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                            ></path>
                          </svg>
                        </span>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`flex flex-col bg-white flex-shrink-0 ${isChatHistoryOpen ? 'w-96' : 'w-0'}`}>
                        <div>
                            <ChatHistory chatHistory={chatHistory} setSelected={setSelected} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

// export default privateRoute(IndexPage);
export default IndexPage;
