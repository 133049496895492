import React from 'react';
import './ChatHistory.css';
import webex from '../../assets/Images/webex.png';
import insights from '../../assets/Images/insight.jpeg';
import gitlab from '../../assets/Images/gitlab.png';
import all from '../../assets/Images/teams.png';


const ChatHistory = ({ chatHistory, setSelected }) => {
    const changeSelected = (item) => {
        setSelected(item)
    }
    return (
        <div className="w-96 bg-white flex-shrink-0">
            <h2 className="text-lg font-semibold m-4">Your History</h2>
            <div className="chat-window">
                {chatHistory.map((historyItem, index) => (
                    <div key={index}
                         className={`history-item mb-4 pl-4 pr-4 ${historyItem.type !== 'question' ? 'history-item-not-question' : ''}`}
                         onClick={() => changeSelected(historyItem.source)}>
                        <div className="flex items-center">
                            {
                                historyItem.type === 'question' &&
                                <img
                                    src={historyItem.source === 'webex' ? webex : historyItem.source === 'gitlab' ? gitlab : historyItem.source === 'insights' ? insights : all }
                                    alt="Avatar"
                                    className="w-8 h-8 rounded-full mr-2"
                                />
                            }
                            <span className="text-gray-700">
                                {historyItem.content}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChatHistory;
