import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useCookies } from "react-cookie";

const PrivateRoute = (Component) => {
    return function WithAuthentication(props) {
        const navigate = useNavigate();
        const [userCookie, setUserCookie] = useCookies();

        async function checkAuth() {
            if (userCookie) {
                console.info('Cookies:', userCookie);
                
                const emailDomain = userCookie?.UserProfile?.emails?.[0]?.split('@')[1];
                if (!emailDomain || emailDomain !== 'presidio.com') {
                    navigate('/');
                }
            }
        }

        useEffect(() => {
            checkAuth();
        }, []);

        return <Component {...props} />;
    };
};

export default PrivateRoute;
