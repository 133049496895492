import React from 'react';
import './LoginPage.css';
import {useNavigate} from "react-router";

const LoginPage = () => {
    let navigate = useNavigate();
    const redirectLogin = () => {
        navigate('login');

    }
    

    return (
        <div className="container">
            <div className="login-side">
                <img src="/assets/Images/grandma_background.png" alt="background" className="background-image" />
            </div>
            <div className="login-box">
                <img src="/assets/Images/gama.png" alt="GAMA logo" className="logo" />
                <h1>Say hi to GAMA </h1>
                <p>Generative AI Message Aggregator</p>
                <button onClick={redirectLogin} className="login-button">Login with Webex</button>
            </div>
        </div>
    );
};
export default LoginPage;
