import {Route, Routes} from "react-router-dom";
import React from 'react';
import LoginPage from "../pages/loginPage/LoginPage";
import IndexPage from "../pages/mainpage/IndexPage";
import RedirectPage from "../pages/redirectPage/RedirectPage";


export default function AppRoutes() {
    return(
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/chatpage" element={<IndexPage />} />
            <Route path="login" element={<RedirectPage loc={'http://backend.qa.presidio.cloud:8001/api/auth/login'}/>}/>
        </Routes>
    );
}
