import React from 'react';

const RedirectPage = ({loc}) => {
    const [state, setState] = React.useState(loc);

    React.useEffect(() => {
        window.location = state;
    });

    return (
        <section>Redirecting...</section>
    )
}

export default RedirectPage;
