import React, {useEffect} from "react";
import webex from '../../assets/Images/webex.png';
import insights from '../../assets/Images/insight.png';
import gitlab from '../../assets/Images/gitlab.png';
import teams from '../../assets/Images/teams.png';

export default function Sidebar({selected, setSelected}) {
    useEffect(() => {
        console.log(selected);
    },[selected])
    const changeSelected = (item) => {
        setSelected(item)
    }
    return(
        <aside className="flex">
            <div
                className="flex h-[100svh] w-60 flex-col overflow-y-auto bg-slate-50 pt-8 dark:border-slate-700 dark:bg-slate-900 sm:h-[100vh] sm:w-64"
            >
                <div className="flex px-4">
                <img src='../../assets/Images/gama.png' alt="Gama Logo" className="w-13 text-blue-600" />
                </div>
                <div className="mx-2 mt-8">
                    <button
                        className="flex w-full gap-x-4 rounded-lg border border-slate-300 p-4 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 5l0 14"></path>
                            <path d="M5 12l14 0"></path>
                        </svg>
                        Chat with GAMA
                    </button>
                </div>
                {/* Previous chats container */}
                <div
                    className="h-1/2 space-y-4 overflow-y-auto border-slate-300 px-2 py-4 dark:border-slate-700"
                >
                    <button
                        className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
                        onClick={() => changeSelected('webex')}
                    >
                        <div className='flex flex-row'>
                            <div
                                className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"
                            >
                                <img
                                    src={webex} // Provide the correct image source
                                    alt="Webex Avatar"
                                    className="w-full h-full rounded-full object-contain"
                                />
                            </div>
                            <h1
                                className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"
                            >
                                Webex
                            </h1>
                        </div>
                        {/*<p className="text-xs text-slate-500 dark:text-slate-400">12 Mar</p>*/}
                    </button>
                    <button
                        className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
                        onClick={() => changeSelected('insights')}
                    >
                        <div className="flex flex-row">
                            <div
                                className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"
                            >
                                <img
                                    src={insights} // Provide the correct image source
                                    alt="Insight Avatar"
                                    className="w-full h-full rounded-full object-contain"
                                />
                            </div>
                            <h1
                                className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"
                            >
                                Insight
                            </h1>
                        </div>
                        {/*<p className="text-xs text-slate-500 dark:text-slate-400">10 Feb</p>*/}
                    </button>
                    {/*<button*/}
                    {/*    className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"*/}
                    {/*    onClick={() => changeSelected('presidio')}*/}
                    {/*>*/}
                    {/*    <div className="flex flex-row">*/}
                    {/*        <div*/}
                    {/*            className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"*/}
                    {/*        >*/}
                    {/*            <img*/}
                    {/*                src="https://www.presidio.com/icheestu/2021/03/favicon-32x32-1.png" // Provide the correct image source*/}
                    {/*                alt="Presidio Avatar"*/}
                    {/*                className="w-full h-full rounded-full object-contain"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    <h1*/}
                    {/*        className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"*/}
                    {/*    >*/}
                    {/*        Presidio Webpage*/}
                    {/*    </h1>*/}
                    {/*    </div>*/}
                    {/*    /!*<p className="text-xs text-slate-500 dark:text-slate-400">22 Jan</p>*!/*/}
                    {/*</button>*/}
                    <button
                        className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
                        onClick={() => changeSelected('gitlab')}
                    >
                        <div className="flex flex-row">
                            <div
                                className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"
                            >
                                <img
                                    src={gitlab} // Provide the correct image source
                                    alt="Gitlab Avatar"
                                    className="w-full h-full rounded-full object-contain"
                                />
                            </div>
                        <h1
                            className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"
                        >
                            Gitlab
                        </h1>
                        </div>
                        {/*<p className="text-xs text-slate-500 dark:text-slate-400">1 Jan</p>*/}
                    </button>
                    {/*<button*/}
                    {/*    className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"*/}
                    {/*    onClick={() => changeSelected('teams')}*/}
                    {/*>*/}
                    {/*    <div className="flex flex-row">*/}
                    {/*        <div*/}
                    {/*            className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"*/}
                    {/*        >*/}
                    {/*            <img*/}
                    {/*                src={teams} // Provide the correct image source*/}
                    {/*                alt="Teams Avatar"*/}
                    {/*                className="w-full h-full rounded-full object-contain"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    <h1*/}
                    {/*        className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"*/}
                    {/*    >*/}
                    {/*        Teams*/}
                    {/*    </h1>*/}
                    {/*    </div>*/}
                    {/*    /!*<p className="text-xs text-slate-500 dark:text-slate-400">1 Jan</p>*!/*/}
                    {/*</button>*/}
                    <button
                        className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
                        onClick={() => changeSelected('all')}
                    >
                        <div className="flex flex-row">
                            <div
                                className="flex items-center justify-center mx-2 my-1 h-6 w-6 rounded-full bg-transparent flex-shrink-0"
                            >
                                <img
                                    src={teams} // Provide the correct image source
                                    alt="Teams Avatar"
                                    className="w-full h-full rounded-full object-contain"
                                />
                            </div>
                            <h1
                                className="text-sm mt-2 ml-1 font-medium capitalize text-slate-700 dark:text-slate-200"
                            >
                                All
                            </h1>
                        </div>
                    </button>
                </div>
                {/*<div className="mt-auto w-full space-y-4 px-2 py-4">*/}
                {/*    <button*/}
                {/*        className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:text-slate-200 dark:hover:bg-slate-800"*/}
                {/*    >*/}
                {/*        <svg*/}
                {/*            xmlns="http://www.w3.org/2000/svg"*/}
                {/*            className="h-6 w-6"*/}
                {/*            viewBox="0 0 24 24"*/}
                {/*            strokeWidth="2"*/}
                {/*            stroke="currentColor"*/}
                {/*            fill="none"*/}
                {/*            strokeLinecap="round"*/}
                {/*            strokeLinejoin="round"*/}
                {/*        >*/}
                {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>*/}
                {/*            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>*/}
                {/*            <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>*/}
                {/*            <path*/}
                {/*                d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"*/}
                {/*            ></path>*/}
                {/*        </svg>*/}
                {/*        User*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:text-slate-200 dark:hover:bg-slate-800"*/}
                {/*    >*/}
                {/*        <svg*/}
                {/*            xmlns="http://www.w3.org/2000/svg"*/}
                {/*            className="h-6 w-6"*/}
                {/*            viewBox="0 0 24 24"*/}
                {/*            strokeWidth="2"*/}
                {/*            stroke="currentColor"*/}
                {/*            fill="none"*/}
                {/*            strokeLinecap="round"*/}
                {/*            strokeLinejoin="round"*/}
                {/*        >*/}
                {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>*/}
                {/*            <path*/}
                {/*                d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"*/}
                {/*            ></path>*/}
                {/*            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>*/}
                {/*        </svg>*/}
                {/*        Settings*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </aside>
    );
}
