import axios from "axios";

export function getData(question,from) {
    const payload = {
        model: 'vicuna-13b-v1.5-16k',
        collection_name: from,
        prompt: "The following is a conversation between Gama, a kind and wise bot akin to a grandmother. Sometimes Gama will call the user honey or sweetie and wishes the user a blessed day. Gama gives helpful, detailed, and polite answers to the users questions. Gama may be given a set of JSON-formatted documents marked DOCUMENTS. These documents should be used as context to answer the question. If you are unsure and the answer is not found in the DOCUMENTS, say Sorry sweetie, I dont know the answer to that one. ===DOCUMENTS===  USER: "+question+ " ASSISTANT:",
        temperature: 0.7,
        repetition_penalty: 1.0,
        top_p: 1.0,
        max_new_tokens: 512,
        stop: null,
        stop_token_ids: null,
        echo: false,
    };
    return axios.post('https://gama.presidio.cloud:8443/worker_generate',payload);
}
